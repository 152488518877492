import classNames from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.scss";

function Navbar(props) {
  const { onSelectMenu, menuActive, scrollPosition } = props;
  const location = useLocation();

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [pathName, setPathName] = useState(location.pathname.replace("/", ""));

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setShowMenu(false);
  };

  const handleClickHome = () => {
    if (pathName !== "/") {
      navigate("/");
      return;
    } else {
      onSelectMenu("home");
      return;
    }
  };

  return (
    <div className="nav-wrapper">
      <div className="navbar">
        <div className="main-left">
          <img
            alt=""
            src={require("assets/images/logos/logo.png")}
            className={classNames("logo-img", { scale: scrollPosition > 300 })}
            onClick={() => handleNavigate("/")}
          />
        </div>
        <div className="main-right">
          <ul className="nav-content">
            <li
              className={classNames("nav-item", {
                select: scrollPosition <= 600 && pathName === "/",
              })}
              onClick={handleClickHome}
            >
              Home
            </li>
            <li
              className={classNames("nav-item", {
                select: scrollPosition > 600 && scrollPosition < 2600,
                hide: pathName !== "/",
              })}
              onClick={() => onSelectMenu("service")}
            >
              Services
            </li>
            <li
              className={classNames("nav-item", {
                select: pathName.replace("/", "") === "job",
              })}
              onClick={() => handleNavigate("/job")}
            >
              Jobs
            </li>
            <li
              className={classNames("nav-item", {
                select: scrollPosition > 2800 && scrollPosition < 3700,
                hide: pathName !== "/",
              })}
              onClick={() => onSelectMenu("about")}
            >
              About us
            </li>
            <button
              className={classNames("contact-btn", { hide: pathName !== "/" })}
              onClick={() => onSelectMenu("contact")}
            >
              Contact us
            </button>
          </ul>
        </div>
      </div>
      <div className="navbar-mobile">
        {showMenu && (
          <div className="bg-overplay" onClick={() => setShowMenu(false)}></div>
        )}
        <div className="main-left">
          <img
            alt=""
            src={require("assets/images/logos/logo.png")}
            className={classNames("logo-img", { scale: scrollPosition > 300 })}
            onClick={() => handleNavigate("/")}
          />
        </div>
        <div className="main-right">
          <button
            className="btn-hamburger"
            type="button"
            onClick={handleShowMenu}
          >
            <img
              src={require("assets/images/icons/hamburger.png")}
              className="icon-menu"
              alt=""
            />
          </button>
          <div className={classNames("navbar-child", { show: showMenu })}>
            <ul className="nav-content">
              <li
                className={classNames("nav-item", {
                  select: scrollPosition < 300 && pathName === "/",
                })}
                onClick={() => handleNavigate("/")}
              >
                Home
              </li>
              <li
                className={classNames("nav-item", {
                  select: scrollPosition > 300 && scrollPosition < 3000,
                  hide: pathName !== "/",
                })}
                onClick={() => onSelectMenu("service")}
              >
                Services
              </li>
              <li
                className={classNames("nav-item", {
                  select: pathName.replace("/", "") === "job",
                  rmb: pathName.replace("/", "") === "job",
                })}
                onClick={() => handleNavigate("/job")}
              >
                Jobs
              </li>
              <li
                className={classNames("nav-item", {
                  select: scrollPosition >= 3000 && scrollPosition < 4000,
                  hide: pathName !== "/",
                })}
                onClick={() => onSelectMenu("about")}
              >
                About us
              </li>
              <button
                className={classNames("nav-btn", {
                  hide: pathName !== "/",
                })}
                onClick={() => onSelectMenu("contact")}
              >
                Contact us
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
