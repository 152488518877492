import classNames from "classnames";
import { useEffect, useState } from "react";
import "./AboutUs.scss";

function AboutUs(props) {
  const { idElement, scrollPosition } = props;
  const [animateProduct, setAnimateProduct] = useState(false);
  const [animateMember, setAnimateMember] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth > 800) {
      if (scrollPosition >= 2600) {
        setAnimateProduct(true);
      }
      if (scrollPosition >= 2300) {
        setAnimateMember(true);
      }
    } else if (window.innerWidth <= 800 && window.innerWidth > 767) {
      if (scrollPosition >= 4300) {
        setAnimateProduct(true);
      }
      if (scrollPosition >= 3900) {
        setAnimateMember(true);
      }
    } else if (window.innerWidth <= 450) {
      if (scrollPosition >= 4800) {
        setAnimateProduct(true);
      }
      if (scrollPosition >= 4900) {
        setAnimateMember(true);
      }
    } else {
      if (scrollPosition >= 3100) {
        setAnimateProduct(true);
      }
      if (scrollPosition >= 2700) {
        setAnimateMember(true);
      }
    }
  }, [scrollPosition]);

  return (
    <div className="about-us" id={idElement}>
      {/* <div
        className={classNames("our-technology", {
          animate: animateProduct,
        })}
      >
        <h1>Our Technology Product Companies</h1>
        <div className="list-product">
          <div className="product-item">
            <h2 className="title-product">Coming soon</h2>
          </div>
          <div className="product-item">
            <h2 className="title-product">Coming soon</h2>
          </div>
          <div className="product-item">
            <h2 className="title-product">Coming soon</h2>
          </div>
          <div className="product-item">
            <h2 className="title-product">Coming soon</h2>
          </div>
        </div>
      </div> */}
      <div className="about-wrapper">
        <div
          className={classNames("our-team", {
            animate: animateMember,
          })}
        >
          <h1>Our Team</h1>
          <div className="list-member">
            <div className="member-item">
              <img
                src={require("assets/images/member/director.png")}
                alt=""
                className="img-member"
              />
              <h4 className="name-member">Ms Sally</h4>
              <p className="office-member">Director</p>
            </div>
            <div className="member-item">
              <img
                src={require("assets/images/member/ace.png")}
                alt=""
                className="img-member"
              />
              <h4 className="name-member">Mr Ace</h4>
              <p className="office-member">Manager Assistant</p>
            </div>
            <div className="member-item">
              <img
                src={require("assets/images/member/hannah.png")}
                alt=""
                className="img-member"
              />
              <h4 className="name-member">Ms Hanna</h4>
              <p className="office-member">Manager Assistant</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
